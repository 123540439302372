import { Component, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { ConfigService } from 'app/services/config.service';
import { ComponentAbstract } from "app/components/component.abstract";
import { LanguageService } from "app/services/language.service";
import { Title } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent extends ComponentAbstract implements OnInit {
    companyLogo: string = "assets/icons/hub71Logo.svg"
    welcomeHeader: string = "Abu Dhabi’s Global Tech Ecosystem"
    welcomeDescription: string =
        `We are a vibrant community of innovators. A thriving, diverse ecosystem of corporate trailblazers,
        national champions, and active investors all working towards the same goal: to shape a future that
        knows no bounds. Welcome to Hub71.`
    textDirection!: string;

    translateSubscription!: Subscription;

    constructor(
        config: ConfigService,
        private language: LanguageService,
        private title: Title
    ) {
        super(config);
        this.title.setTitle(this.config.getProjectName());
    }

    ngOnInit() {
        console.log(environment)

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            this.textDirection = this.config.getDirectionFromLanguage(selectedLanguage.locale)

            Object.assign(this, translate.typography)
        });
    }
}
